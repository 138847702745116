import { Component } from "react";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
    this.setState({ hasError: true });
  }
  handleRefresh = () => {
    window.location.reload(); // This refreshes the page
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='my-app-base p-app-base'>
          <div className='flex justify-center'>
            <svg viewBox="0 0 24 24" width="56" height="56" fill='gray' aria-hidden="true" class="r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-lrvibr r-m6rgpd r-1bwzh9t r-1ui5ee8"><g><path d="M3.707 21.71l17-17-1.414-1.42-2.555 2.56C15.492 4.7 13.828 4 12 4 9.142 4 6.686 5.71 5.598 8.16 2.96 8.8 1 11.17 1 14c0 2.06 1.04 3.88 2.625 4.96l-1.332 1.33 1.414 1.42zm1.37-4.2C3.839 16.83 3 15.51 3 14c0-2.03 1.506-3.7 3.459-3.96l.611-.09.201-.58C7.947 7.41 9.811 6 12 6c1.275 0 2.438.48 3.322 1.26L5.077 17.51zM8.243 20l2-2H18c1.657 0 3-1.34 3-3s-1.343-3-3-3v-2c2.761 0 5 2.24 5 5s-2.239 5-5 5H8.243z"></path></g></svg>
          </div>
          <div className='flex justify-center text-[#6D6D6D]'>
            <h1>Something went wrong.</h1>
            <p>Please check your connection or try again later.</p>
          </div>
          <div className="flex justify-center">
            <button onClick={this.handleRefresh} className="px-app-base py-app-xs bg-[#45A8FC] hover:bg-[#69BAFD] rounded-[25px]" >Retry</button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}