export const saveUser = async (data) => {
  const { token, email, displayName, userName, userId } = data;

  return await Promise.all([
    localStorage.setItem('token', token),
    localStorage.setItem('email', email),
    localStorage.setItem('displayName', displayName),
    localStorage.setItem('userName', userName),
    localStorage.setItem('userId', userId),
  ]);
};

export const loguotUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('email');
  localStorage.removeItem('displayName');
  localStorage.removeItem('userName');
  localStorage.removeItem('userId');
};

export const getToken = () => {
  return localStorage.getItem('token');
};