import React, { useState } from 'react';
import './BorderBottom.styles.css';

export const BorderBottom = (props) => {

  const { type, id, name, value, onChange, onBlur, required, onFocus } = props;
  const [focused, setFocused] = useState(false);


  const capitalizedFirstLetter = React.useCallback((str) => {
    str = str.replace('-', ' ');
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }, []);


  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleFocus = ()  => {
    setFocused(true);
    onFocus();
  }

  const handleBlur = () => {
    onBlur();
    setFocused(false)
  }

  

  return (
    <div className={`input-border-bottom relative w-full ${focused ? "input-border-bottom-focused" : ""} border-b-[0.5px] border-b-gray-300`}>
      <input
      className="w-full p-2 text-app-base border-none bg-transparent  outline-none"
        type={type || 'text'}
        id={id}
        placeholder={capitalizedFirstLetter(name)}
        required={required || false}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
    </div>
  );
};
