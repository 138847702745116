import React from 'react';
import './SmallOne.styles.css';

export const SmallOne = ({ color, onClick }) => {
  return (
    <button onClick={onClick} className='voiting-man-icon-small '>
      <span className={`voiting-man-circle-small voiting-man-small-${color}`}></span>
      <span className={`voiting-man-rectangle-small voiting-man-small-${color}`}></span>
    </button>
  );
};
