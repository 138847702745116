import React from 'react';
import './ErrorPage.css'
import { NavLink } from 'react-router-dom';
import { Button } from '@headlessui/react'

export const ErrorPage = () => {
  return (
    <>
      <NavLink to={'/'} className='absolute m-app-2xl'>
        <Button className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
          Go to Homepage
        </Button>
      </NavLink>
      <div className='error-page-wrapper'>
      </div>
    </>
  );
};
