import { RouterPaths } from './router';

export const App = () => {

  return (
    <RouterPaths />
  );
}


export default App;
