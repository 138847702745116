import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loguotUser } from '../../utils/auth';
import { Icons } from '../../UI';
import { Links } from '../../UI/Links/Links';


export const Navigation = () => {

  let location = useLocation();

  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  let userIsLogged = !!userId;

  const onLogout = (e) => {
    loguotUser();
    navigate('/');
    window.location.reload();
  };


  return (
    <nav className='no-scrollbar flex flex-1'>
      <ul className='flex-1' >
        <li>
          <Links
            variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
            iconVariant={Icons.variants.HOME_FULL}
            to={`/`}
            text='Home'
          />
        </li>
        {
          userIsLogged
            ?
            <li>
              <Links
                variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                iconVariant={Icons.variants.LAYER}
                to={`/user-questions/${userId}`}
                text='My pinions'
              />
            </li>
            : null
        }
        {
          userIsLogged
            ?
            <>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.PLUS_SQUARE}
                  to={`/create-pinion`}
                  state={{ backgroundLocation: location }}
                  text='New pinion'
                />
              </li>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.BOOKMARK_FULL}
                  to={`/bookmarks`}
                  text='Bookmarks'
                />
              </li>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.BELL_FULL}
                  to={`/notifications`}
                  text='Notifications'
                />
              </li>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.PROFILE_FULL}
                  to={`/profile/${userId}`}
                  text='Profile'
                />
              </li>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.POWER_OFF}
                  text='Logout'
                  to={'/logout'}
                  onClick={onLogout}
                  preventNavigation={true}
                />
              </li>
            </>
            : null
        }
        {
          !userIsLogged
            ? <>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.USER_FULL}
                  text='Login'
                  to={'/login'}
                  state={{ backgroundLocation: location }}
                />
              </li>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.ID_CARD}
                  text='Register'
                  to={'/register'}
                  state={{ backgroundLocation: location }}
                />
              </li>
            </>
            : null
        }
      </ul>
    </nav>
  );
};
