import React, { useEffect, useRef, useState } from 'react'
import { Icons, Spinner } from '../../../UI';
import { DialogComponent } from '../../../components/DialogComponent/DialogComponent';
import { useUploadUserImageMutation } from '../../../apis/main';

export const ProfileImage = ({ imageUrl, userId, currentUserId }) => {

  const [uploadUserImage, { isLoading }] = useUploadUserImageMutation();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imgSrc, setImgSrc] = useState(imageUrl ? `${BASE_URL}/${imageUrl}` : `/0d64989794b1a4c9d89bff571d3d5842.jpg`);

  const changeImgHandler = () => {
    inputRef.current.click()
  }
  useEffect(() => {
    if (!file) return;

    (async () => {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('imageUrlFor', 'profile');

      const result = await uploadUserImage(formData);

      if (result.error) {
        setErrorMessage(result.error.message);
        setIsOpen(true);
      } else {
        setImgSrc(`${BASE_URL}/${result.data.imgUrl}`);
      }

    })()
  }, [file, BASE_URL, uploadUserImage]);

  if (userId !== currentUserId) {
    return (
      <div className="absolute h-32 w-32 bottom-[-15%] left-4 rounded-full ">
        <img
          className="rounded-full absolute inset-0 object-cover w-full h-full border-app-sm border-black"
          src={imgSrc}
          alt=''
        />
      </div>
    );
  }

  return (
    <>
      <DialogComponent
        isOpen={isOpen}
        closeDialog={() => setIsOpen(false)}
        content={errorMessage}
      />
      <div className="group absolute h-32 w-32 bottom-[-15%] left-4 rounded-full hover:bg-app-card  " onClick={changeImgHandler}>
        {
          isLoading
            ? <div className='flex h-full justify-center items-center'>
              <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
            </div>
            : <>
              <img
                className="group-hover:opacity-40 rounded-full absolute inset-0 object-cover w-full h-full border-app-sm hover:opacity-40 border-black cursor-pointer"
                src={imgSrc}
                // src='http://192.168.0.8:3030/images/arrow-up.png'
                alt=''
              />
              <input type='file' ref={inputRef} onChange={ev => setFile(ev.target.files[0])} className=' invisible'></input>

              <div className={`group-hover:visible absolute top-2 right-2 h-7 w-7 invisible cursor-pointer`}>
                <Icons variant={Icons.variants.EDIT} classes={`fill-white`} />
              </div>
            </>
        }
      </div>
    </>
  )
}