import React from 'react';
import * as Variants from './Variants/index';

export const Button = React.memo((props) => {

  const VariantComponent = React.useMemo(() => {
    return Variants[props.variant] || <div>There is missing variant for {props.variant}</div>;
  }, [props.variant]);

  return (
    <VariantComponent {...props} />
  );
});

Button.variants = {
  OTLINE_ON_HOVER_ROUNDED_DANGER_LARGE: 'OutlineOnHoverRoundedDangerLarge',
  ROUNDED_BLUE:'RoundedBlue',
  ROUNDED_DARK: 'RoundedDark',
  ROUNDED_GRAY_LARGE: 'RoundedGrayLarge',
}