import React, { useCallback, useState } from 'react';
import { VoitingCard } from '../VoitingCard/VoitingCard';
import { Icons, UserName } from '../../UI';
import { useLocation, useNavigate } from 'react-router-dom';
import { Links } from '../../UI/Links/Links';
import { getToken, loguotUser } from '../../utils/auth';

import './QuestionCard.css';
import { countComments } from '../../utils/helpers';
import { ShareButton } from './ShareButton';
import { useAnswerQuestionMutation, useBookMarkQuestionMutation } from '../../apis/main';
import { Avatar } from '../Avatar/Avatar';

export const QuestionCard = ({ data }) => {

  const [bookMarkQuestion] = useBookMarkQuestionMutation();
  const [answerQuestion] = useAnswerQuestionMutation();


  const userIsloggedIn = getToken();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUserId = localStorage.getItem('userId');

  const displayName = data.creator.displayName;
  const userName = data.creator.userName;
  const userId = data.creator.creatorId;
  const profileImgUrl = data.creator.profileImgUrl;
  const question = data.title;
  const questionId = data.questionId;
  const likes = data.likes;
  const dislikes = data.dislikes;
  const answered = !currentUserId ? null : data.answered;
  const commentsCount = Array.isArray(data.comments) ? countComments(data.comments) : data.comments;
  const [isBookmarked, setIsBookmarked] = useState(data.bookmarked)


  const onCardClick = (e) => {
    navigate(`/question/${questionId}`);
  };

  const onBookMark = useCallback((event) => {
    event.stopPropagation();
    setIsBookmarked(prev => !prev);

    const token = getToken();
    if (!token) return;

    const userId = localStorage.getItem('userId');

    (async () => {
      const result = await bookMarkQuestion({ questionId, userId });

      if (result.error) {
        if (result.error.message === 'Not authorized') {
          loguotUser();
          navigate('/login', { state: { backgroundLocation: location, message: 'Your session has expired. Please log in again.' } });
        }
      }
    })();


  }, [questionId, bookMarkQuestion, location, navigate]);

  const onAnswerHandler = (answer) => {
    const token = getToken();
    if (!token) return;

    (async () => {
      const result = await answerQuestion({ questionId, answer });

      if (result.error) {
        if (result.error.message === 'Not authorized') {
          loguotUser();
          navigate('/login', { state: { backgroundLocation: location, message: 'Your session has expired. Please log in again.' } });
        }
      }

    })();
  };


  const handleNavigation = (event) => {
    if (event.target.tagName === 'A') event.stopPropagation();
  }

  return (
    <div onClick={onCardClick} className='flex bg-app-card rounded-app-lg first:mt-0 mt-app-base p-app-lg cursor-pointer'>
      <Avatar
        userId={userId}
        displayName={displayName}
        userName={userName}
        imageUrl={profileImgUrl}
      />
      <div className='flex flex-1 flex-col px-app-base'>
        <div className='flex flex-col pb-app-base'>
          <div onClick={handleNavigation}>
            <Links
              variant={Links.variants.PRIMARY} to={`/profile/${userId}`}>
              {displayName}
            </Links>
            <div className='flex-0' >
              <UserName userName={userName} />
            </div>
          </div>
          <div className='flex mt-app-base'>
            <span>
              {question}
            </span>
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='flex flex-col flex-1 mr-app-base'>
            <VoitingCard likes={likes} dislikes={dislikes} answered={answered}
              onAnswer={onAnswerHandler}
            />

            <div className='flex justify-between text-app-gray'>
              <div>
                <button className="question-card-actions-buttons">
                  <Icons variant={Icons.variants.COMMENT} />
                </button>
                {
                  commentsCount !== null || commentsCount !== undefined || commentsCount !== false
                    ? <span className='text-app-base ml-app-xs'>{commentsCount}</span>
                    : null
                }
              </div>

              {
                userIsloggedIn
                  ? <button className="question-card-actions-buttons" onClick={onBookMark}>
                    <Icons
                      variant={isBookmarked ? Icons.variants.BOOKMARK_FULL : Icons.variants.BOOKMARK}
                      styles={isBookmarked ? 'text-app-secondary' : undefined}
                    />
                  </button>
                  : null
              }

              <ShareButton questionId={questionId} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
