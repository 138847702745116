import React, { useState } from 'react';
import './NavigationMobile.styles.css';
import { Links } from '../../UI/Links/Links';
import { loguotUser } from '../../utils/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '../../UI';

export const NavigationMobile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const [menuOpen, setMenuOpen] = useState(false);

  let userIsLogged = !!userId;

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setMenuOpen(curr => !curr);
  };

  const onLogout = () => {
    loguotUser();
    toggleMenu();
    navigate('/');
  };

  return (
    <div className="navigation-mobile laptop:hidden">
      {/* Button to toggle menu visibility */}
      <button className="menu-toggle-btn text-app-2xl" onClick={toggleMenu}>
        <Icons variant={Icons.variants.HAMBURGER_2} />
      </button>

      {/* Mobile navigation menu */}
      <nav className={`px-app-base mobile-navigation ${menuOpen ? 'menu-open' : 'menu-closed'}`}>
        <div className='flex justify-end'>
          <button className="text-app-2xl p-app-base" onClick={toggleMenu}>
            <Icons variant={Icons.variants.X} />
          </button>
        </div>
        <ul className='flex-1'>
          <li>
            <Links
              onClick={toggleMenu}
              variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
              iconVariant={Icons.variants.HOME_FULL}
              to={`/`}
              text='Home'
            />
          </li>
          {userIsLogged && (
            <li>
              <Links
                onClick={toggleMenu}
                variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                iconVariant={Icons.variants.LAYER}
                to={`/user-questions/${userId}`}
                text='My pinions'
              />
            </li>
          )}
          {userIsLogged ? (
            <>
              <li>
                <Links
                  onClick={toggleMenu}
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.PLUS_SQUARE}
                  to={`/create-pinion`}
                  state={{ backgroundLocation: location }}
                  text='New pinion'
                />
              </li>
              <li>
                <Links
                  onClick={toggleMenu}
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.BOOKMARK_FULL}
                  to={`/bookmarks`}
                  text='Bookmarks'
                />
              </li>
              <li>
                <Links
                  onClick={toggleMenu}
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.BELL_FULL}
                  to={`/notifications`}
                  text='Notifications'
                />
              </li>
              <li>
                <Links
                  onClick={toggleMenu}
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.PROFILE_FULL}
                  to={`/profile/${userId}`}
                  text='Profile'
                />
              </li>
              <li>
                <Links
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.POWER_OFF}
                  text='Logout'
                  to={'/logout'}
                  onClick={onLogout}
                  preventNavigation={true}
                />
              </li>
            </>
          ) : (
            <>
              <li>
                <Links
                  onClick={toggleMenu}
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.USER_FULL}
                  text='Login'
                  to={'/login'}
                  state={{ backgroundLocation: location }}
                />
              </li>
              <li>
                <Links
                  onClick={toggleMenu}
                  variant={Links.variants.BOUNCING_ICON_WITH_TEXT}
                  iconVariant={Icons.variants.ID_CARD}
                  text='Register'
                  to={'/register'}
                  state={{ backgroundLocation: location }}
                />
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};
