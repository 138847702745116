import { getToken } from "../utils/auth";

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:3030';

export const MainService = {

  getQuestion: async (body) => {
    const { questionId } = body;
    const url = `/questions/single-question/${questionId}`;

    return Request.get({ url });
  },

  getQuestions: async () => Request.get({ url: '/questions' }),

  getQuestionsByTitle: async ({ title }) => Request.get({ url: `/questions/by-title/${title}` }),

  getUserQuestions: async (userId) => {

    return Request.get({ url: `/questions/user/${userId}` });
  },

  getUserBookmarks: async () => Request.get({ url: `/questions/bookmarks/` }),

  getUser: async (userId) => Request.get({ url: `/users/${userId}` }),

  answerQuestion: async (body) => {
    const { questionId, answer } = body;
    const url = `/questions/answer/${questionId}`;

    return Request.post({ url, body: { answer } });
  },

  bookmarkQuestion: async (body) => {
    const { questionId } = body;
    const url = `/questions/bookmark/${questionId}`;

    return Request.post({ url });
  },

  addNewQuestion: async (data) => {

    const { title, category } = data;
    const url = `/questions/create`;

    return Request.post({ url, body: { title, category } });
  },

  commentQuestion: async (data) => {
    const { content, questionId } = data;
    const url = `/comments/question/${questionId}`;

    return Request.post({ url, body: { content } });
  },

  replyComment: async (data) => {
    const { content, commentId, questionId } = data;
    const url = `/comments/reply/${questionId}/${commentId}`;

    return Request.post({ url, body: { content } });
  },

  getReplies: async (data) => {
    const { commentId } = data;
    const url = `/comments/comment/replies/${commentId}`;

    return Request.get({ url });
  },

  answerComment: async (data) => {
    const { commentId, answer } = data;
    const url = `/comments/answer/${commentId}`;

    return Request.post({ url, body: { answer } });
  },

  uploadUserImage: async (body) => Request.post({ url: '/users/change-profile-image', body, isFormData: true }),

  followUser: async ({ userId }) => Request.post({ url: `/users/follow/${userId}` }),

  unfollowUser: async ({ userId }) => Request.post({ url: `/users/unfollow/${userId}` }),

  submitLogin: async (body) => Request.post({ url: `/auth/login`, body }),


  submitRegister: async (body) => Request.post({ url: `/auth/signup`, body }),
}

class Request {

  static async get({ url = '' }) {
    const token = getToken();

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (token) {
      options.headers.authorization = token;
    }

    try {
      const res = await fetch(`${BASE_URL}${url}`, options);

      if (!res.ok) {
        const error = await res.json();
        throw error;
      }

      const data = await res.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async post({ url = '', body = {}, isFormData = false }) {
    const token = getToken();

    const options = {
      method: 'POST',
      headers: {
        'authorization': token,
      },
      body: isFormData ? body : JSON.stringify(body), // Handle FormData separately
    };

    // Set default Content-Type if the body is not FormData
    if (!isFormData) {
      options.headers['Content-Type'] = 'application/json';
    }

    try {
      const res = await fetch(`${BASE_URL}${url}`, options);

      if (!res.ok) {
        const error = await res.json();
        throw error;
      }

      const data = await res.json();
      return data;
    } catch (error) {

      throw error;
    }
  }
};