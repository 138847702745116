import React from 'react';
import { ButtonLoader } from '../../../ButtonLoader/ButtonLoader';

export const RoundedDark = ({ onClick, children, disabled = false, isLoading = false }) => {
  return (
    <button
      className='disabled:cursor-not-allowed disabled:opacity-[0.4] flex items-center rounded-[25px] mt-app-sm mr-app-base px-app-base text-white p-app-s hover:bg-[#3F3F3F] disabled:bg-[#3F3F3F]'
      onClick={onClick} disabled={disabled || isLoading}
    >
      {
        isLoading
          ? <ButtonLoader variant={ButtonLoader.variants.SMALL} />
          : null
      }
      {children}
    </button>
  );
};
