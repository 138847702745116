import React from 'react';
import * as Variants from './Variants/index';

export const Links = (props) => {

  const VariantComponent = React.useMemo(() => {
    return Variants[props.variant] || <p>There is no such variant for ${props.variant}</p>
  }, [props.variant]);

  return (
    <VariantComponent {...props} />
  );
};

Links.variants = {
  PRIMARY: 'Primary',
  BOUNCING_ICON_WITH_TEXT: 'BouncingIconWithText',
};
