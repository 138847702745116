import React from 'react';
import { MiddleSideMobile } from '../MiddleSideMobile/MiddleSideMobile';
import { HeaderMobile } from './HeaderMobile';
import { NavigationMobile } from './NavigationMobile';

export const Mobile = () => {
  return (
    <>
      <HeaderMobile />
      <NavigationMobile />

      <div className='laptop:hidden flex flex-1 justify-center overflow-scroll no-scrollbar'>
        <MiddleSideMobile />
      </div>
      {/* <div className='h-[30px] w-full fixed bottom-0 bg-app-primary'>

      </div> */}
    </>
  );
};