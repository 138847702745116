import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { CreateQuestion, Layout, Modal, SignInForm, SignUpForm } from "./components";
import { ErrorPage, Home, Notifications, Profile, Question, UserBookmarks, UserQuestions, SearchQuestions } from "./pages";

export const RouterPaths = () => {

  let location = useLocation();

  // The `backgroundLocation` state is the location that we were at when one of
  // the gallery links was clicked. If it's there, use it as the location for
  // the <Routes> so we show the gallery in the background, behind the modal.
  let state = location.state || {};

  return (
    <>
      <Routes location={state.backgroundLocation || location}>
        <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
          <Route index element={<Home />} />
          <Route path="/login" element={<Modal navigateTo={'/'}><SignInForm /></Modal>} />
          <Route path="/register" element={<Modal navigateTo={'/'}><SignUpForm /></Modal>} />
          <Route path="/create-pinion" element={<Modal navigateTo={'/'}><CreateQuestion /></Modal>} />
          <Route path="/profile/:userId" element={<Profile />} />
          <Route path="/bookmarks" element={<UserBookmarks />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/question/:questionId" element={<Question />} />
          <Route path="/user-questions/:userId" element={<UserQuestions />} />
          <Route path="/search-questions/:title" element={<SearchQuestions />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes >

      {/* Show the modal when a `backgroundLocation` is set */}
      {
        state.backgroundLocation && (
          <Routes>
            <Route path="/login" element={<Modal navigateTo={-1}><SignInForm /></Modal>} />
            <Route path="/register" element={<Modal navigateTo={-1}><SignUpForm /></Modal>} />
            <Route path="/create-pinion" element={<Modal navigateTo={-1}><CreateQuestion /></Modal>} />
          </Routes>
        )
      }
    </>
  );
};