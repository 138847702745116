import React from 'react';
import './Outline.styles.css';

export const Outline = React.memo(({ id, handleCheckBoxChange, label }) => {

  const handleCheck = React.useCallback((e) => {
    handleCheckBoxChange(e.target.checked);
  }, [handleCheckBoxChange]);

  return (
    <label className="checkbox-label" htmlFor={id || "input-checkbox"}>
      <span className={`checkbox-container `}>
        <input onClick={handleCheck} type="checkbox" id={id || "input-checkbox"} name=""
          className="absolute w-[0] h-[0]"
        />
        <i className="fa-solid fa-check left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 text-app-primary absolute"></i>
      </span>
      <span className="ml-4">{label}</span>
    </label>
  );
});
