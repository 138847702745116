import { createApi } from '@reduxjs/toolkit/query/react'
import { MainService } from "../services/main";
import { saveUser } from '../utils/auth';

// Define a service using a base URL and expected endpoints
export const mainApi = createApi({
  reducerPath: 'RTKQ/questions',
  tagTypes: ['Questions'],  // This tells RTK Query that we'll be using 'Questions' as a tag type
  endpoints: (builder) => ({
    // When destructoring this function from mainApi we right use in start and Query in the end
    // Also writing it camle case , the function below became useGetQuestionQuery, 
    //as we can see in the last line export const { useGetQuestionQuery, useGetQuestionsQuery } = mainApi

    getQuestion: builder.query({
      queryFn: async (body) => {
        try {
          const response = await MainService.getQuestion(body);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      providesTags: (result, error, arg) => {
        return [{ type: 'Questions', id: arg.questionId }];
      },
    }),
    // This became useGetQuestionsQuery 
    getQuestions: builder.query({
      queryFn: async () => {
        try {
          const response = await MainService.getQuestions();
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      providesTags: (result) => {
        return result
          ? [...result.map(({ _id }) => ({ type: 'Questions', id: _id })), 'Questions']
          : ['Questions']; // Provides tags based on the question IDs
      },
      keepUnusedDataFor: 10 * 60
    }),

    getUserQuestions: builder.query({
      queryFn: async (userId) => {

        try {
          const response = await MainService.getUserQuestions(userId);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      providesTags: (result, error, userId) => [{ type: 'UserQuestions', id: userId }, 'Questions'], // Provide both user-specific and global tags
      keepUnusedDataFor: 10 * 60
    }),

    getUser: builder.query({
      queryFn: async (userId) => {
        try {
          const response = await MainService.getUser(userId);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      providesTags: (result, error, userId) => [{ type: 'User', id: userId }],
    }),

    addQuestion: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.addNewQuestion(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'UserQuestions', id: userId }, // Invalidate the specific user's questions
        'Questions', // Invalidate the global questions tag
      ],
    }),

    getQuestionsByTitle: builder.query({
      queryFn: async ({ title }) => {
        try {
          const response = await MainService.getQuestionsByTitle({ title });
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      keepUnusedDataFor: 10 * 60
    }),



    getUserBookmarks: builder.query({
      queryFn: async (userId) => {

        try {
          const response = await MainService.getUserBookmarks();
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      providesTags: (result, error, userId) => [{ type: 'Bookmarks', id: userId }, 'Questions'], // Provide both user-specific and global tags
      keepUnusedDataFor: 10 * 60
    }),

    bookMarkQuestion: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.bookmarkQuestion(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: (result, error, { userId }) => {
        console.log({ result });
        return [
          { type: 'UserQuestions', id: userId }, // Invalidate the specific user's questions
          { type: 'Bookmarks', id: userId }, // Invalidate the specific user's bookmarks
          'Questions', // Invalidate the global questions tag
        ]
      },
    }),

    getReplies: builder.query({
      queryFn: async (data) => {
        try {
          const response = await MainService.getReplies(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      providesTags: (result) => {
        console.log({ result });
        return result
          ? [...result.map(({ _id }) => ({ type: 'Replies', id: _id })), 'Replies']
          : ['Replies']; // Provides tags based on the question IDs
      },
    }),

    postComment: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.commentQuestion(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: ['Questions'],
    }),

    replyComment: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.replyComment(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: ['Replies', 'Questions'],
    }),

    login: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.submitLogin(data);
          const { token, email, userId, displayName, userName } = response;
          await saveUser({ token, email, userId, displayName, userName });

          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      // invalidatesTags: (result, error) => {
      //   if (!result) return [];
      //   const userId = result.userId;
      //   return [
      //     { type: 'UserQuestions', id: userId }, // Invalidate the specific user's questions
      //     { type: 'Bookmarks', id: userId }, // Invalidate the specific user's bookmarks
      //     'Questions', // Invalidate the global questions tag
      //   ]
      // },
    }),

    answerQuestion: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.answerQuestion(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: ['Questions'],
    }),

    answerComment: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.answerComment(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: ['Questions', 'Replies'],
    }),

    uploadUserImage: builder.mutation({
      queryFn: async (body) => {
        try {
          const response = await MainService.uploadUserImage(body);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: (result, error, userId) => {
        return [{ type: 'User', id: result.userId }, 'Questions', 'Replies']
      },
    }),

    followUser: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.followUser(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
      ],
    }),

    unfollowUser: builder.mutation({
      queryFn: async (data) => {
        try {
          const response = await MainService.unfollowUser(data);
          return {
            data: response,
          };
        } catch (error) {
          return {
            error: error,
          };
        }
      },
      invalidatesTags: (result, error, { userId }) => [
        { type: 'User', id: userId },
      ],
    }),

  }),
})

export const {
  useGetQuestionQuery,
  useGetQuestionsQuery,
  useGetUserQuery,
  useAddQuestionMutation,
  useGetQuestionsByTitleQuery,
  useGetUserQuestionsQuery,
  useGetRepliesQuery,
  useGetUserBookmarksQuery,
  useBookMarkQuestionMutation,
  useGetPockemonQuery,
  usePostCommentMutation,
  useReplyCommentMutation,
  useLoginMutation,
  useAnswerQuestionMutation,
  useAnswerCommentMutation,
  useUploadUserImageMutation,
  useFollowUserMutation,
  useUnfollowUserMutation, } = mainApi
