import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Input } from "../../UI";
import './SignInForm.styles.css';
import { Spinner } from "../../UI/Spinner/Spinner";
import isEmail from 'validator/lib/isEmail';
import { useInput } from "../../hooks/useInput";
import { useLoginMutation } from "../../apis/main";

export const SignInForm = () => {

  const [login, { isLoading }] = useLoginMutation();

  const location = useLocation();
  const message = location.state?.message;

  const {
    inputValue: emailValue,
    hasError: emailHasError,
    onInputChange: onEmailChange,
    onInputBlur: onEmailBlur,
  } = useInput(isEmail);
  const {
    inputValue: passwordValue,
    hasError: passwordHasError,
    onInputChange: onPasswordChange,
    onInputBlur: onPasswordBlur,
  } = useInput((value) => value.length >= 6);

  const navigate = useNavigate();
  const [serverError, setServerError] = useState(message);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (emailHasError || passwordHasError) return;

    setServerError(null);

    const submitLogin = async () => {

      const body = {
        email: emailValue,
        password: passwordValue,
      };

      const result = await login(body);
      if (result.error) {
        setServerError(result.error.message);
      } else {
        navigate('/');
        window.location.reload();
      }
    };

    submitLogin();
  }


  return (
    <>
      {isLoading && <Spinner variant={Spinner.variants.CIRCLE} />}
      <div className="signin-form-wrapper">
        <div className="signin-form-container">
          <div className="flex justify-start mb-[50px]">
            <div className="w-[200px]">
              <img src="/logo-new.png" alt="" />
            </div>
          </div>
          {
            serverError
              ? <span className="text-app-base text-red-500 mb-app-xl">{serverError}</span>
              : null
          }
          <form onSubmit={handleSubmit} className="flex flex-1 flex-col justify-center gap-app-xl ">
            <Input
              fowardRef={null}
              variant={Input.variants.OUTLINE}
              name={'email'}
              id={'emailid'}
              value={emailValue}
              hasError={emailHasError}
              errorMessage={'Invalid email'}
              onChange={onEmailChange}
              onBlur={onEmailBlur}
              required={true}
            />
            <Input
              fowardRef={null}
              variant={Input.variants.OUTLINE_PASSWORD}
              name={'password'}
              id={'passwordid'}
              hasError={passwordHasError}
              value={passwordValue}
              errorMessage={'Password must be at least 6 charachters'}
              onChange={onPasswordChange}
              onBlur={onPasswordBlur}
              required={true}
            />

            <button className="flex p-app-base rounded-app-base justify-center bg-[#3742fa] hover:bg-[#2828dc] disabled:opacity-40 disabled:hover:bg-[#3742fa]"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
