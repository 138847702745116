import React from 'react';
import './Primary.styles.css';

export const Primary = ({ color, onClick }) => {
  return (
    <button onClick={onClick} className='voiting-man-icon '>
      <span className={`voiting-man-circle voiting-man-${color}`}></span>
      <span className={`voiting-man-rectangle voiting-man-${color}`}></span>
    </button>
  );
};
