import React, { useState } from 'react';
import '../Styles/Outline.styles.css';
import { Icons } from '../../../Icons/Icons';
import './OutlinePassword.css';

export const OutlinePassword = (props) => {

  const { id, name, value, required, onBlur, hasError, errorMessage, onChange } = props;
  const [type, setType] = useState('password');

  const capitalizedFirstLetter = React.useCallback((str) => {
    str = str.replace('-', ' ');
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }, []);

  const onIconClick = () => {
    setType(curr => curr === 'password' ? 'text' : 'password');
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleBlur = () => onBlur();

  return (
    <div className='flex flex-col'>
      <div className="input-container">
        <input
          type={type}
          id={id}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={name}
          required={required || false} />
        <label htmlFor={id}>{capitalizedFirstLetter(name)}</label>
        <div onClick={onIconClick} className='input-icon-right'>
          {
            type === 'password'
              ? <div className="input-actions-buttons">
                <Icons variant={Icons.variants.EYE_FULL_SLASH} />
              </div>
              : <div className="input-actions-buttons">
                <Icons variant={Icons.variants.EYE_FULL} />
              </div>
          }
        </div>
      </div>
      {
        hasError
          ? <span className='text-red-500 p-app-base text-app-base'>
            {errorMessage}
          </span>
          : null
      }
    </div>
  );
};
