import React, { useState } from 'react';
import './Avatar.styles.css';
import { AvatarPopUp } from './AvatarPopUp';
import { NavLink } from 'react-router-dom';

export const Avatar = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = async (event) => {
    setShowPopup(true);
  };
  const handleMouseLeave = async (event) => {
    setShowPopup(false);
  };

  return (
    <div className='avatar' onClick={(event) => event.stopPropagation()}>
      <div className='avatar-image-container'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NavLink to={`/profile/${props.userId}`}>
          <img
            src={!props.imageUrl ? '/profile-man.png' : `${BASE_URL}/${props.imageUrl}`} alt=""
            className='bg-gray-200 rounded-full'
          />
        </NavLink>
        {
          showPopup
            ? <AvatarPopUp {...props} />
            : null
        }
      </div>
    </div>
  );
};
