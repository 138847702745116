import React from 'react';
import { useAnswer } from '../../hooks/useAnswer';
import { Icons, UserName } from '../../UI';
import Moment from 'react-moment';
import { Avatar } from '../Avatar/Avatar';

export const Comment = (props) => {

  const { data } = props;
  const displayName = data.creator.displayName;
  const userName = data.creator.userName;
  const userId = data.creator.creatorId;
  const profileImgUrl = data.creator.profileImgUrl;
  const createdAt = data.createdAt;
  const content = data.content;
  const likes = data.likes;
  const dislikes = data.dislikes;
  const answered = data.answered
  const commentId = data._id;

  const onAnswer = props.onAnswer

  const {
    feedback,
    handleLike: handleLikeComment,
    handleDisike: handleDislikeComment, } = useAnswer({ answered, likes, dislikes });

  const likeIconVariant = feedback.answered === true ? 'LIKE_FULL' : 'LIKE';
  const dislikeIconVariant = feedback.answered === false ? 'DISLIKE_FULL' : 'DISLIKE';

  const likeHandler = (event) => {
    onAnswer(commentId, true);
    handleLikeComment();
  };

  const dislikeHandler = (event) => {
    onAnswer(commentId, false);
    handleDislikeComment();
  };


  return (
    <div className='flex bg-app-card rounded-app-lg pt-app-base cursor-pointer last:mb-app-base'>
      <Avatar
        userId={userId}
        displayName={displayName}
        userName={userName}
        imageUrl={profileImgUrl}
      />
      <div className='flex flex-1 flex-col px-app-base'>
        <div className='flex flex-col'>
          <div className='flex flex-col'>
            <div className='flex gap-app-base mb-app-lg '>
              <div className='flex flex-col'>
                <span className='text-app-white text-app-base'>
                  {displayName}
                </span>
                <span className='text-app-gray text-app-base'>
                  <UserName userName={userName} />
                </span>
              </div>
              <span className='text-app-base text-app-gray'>
                <Moment fromNow>{new Date(createdAt)}</Moment>
              </span>
            </div>
            <div className='text-app-gray'>
              <span>
                {content}
              </span>
              <div className='flex gap-app-sm'>
                <div className='flex gap-app-sm' >
                  <div onClick={likeHandler} className='flex w-[24px] h-[24px] text-white'>
                    <Icons variant={Icons.variants[likeIconVariant]} />
                  </div>
                  <span className='flex items-center text-app-base'>
                    {feedback.likes}
                  </span>
                </div>
                <div className='flex gap-app-sm'>
                  <div onClick={dislikeHandler} className='flex w-[24px] h-[24px] text-white'>
                    <Icons variant={Icons.variants[dislikeIconVariant]} />
                  </div>
                  <span className='flex items-center text-app-base'>
                    {feedback.dislikes}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};