import React, { useEffect, useRef, memo } from 'react';
import './VotingBar.styles.css';
import { Popup } from './Popup';

export const VotingBar = memo(({ likes, dislikes }) => {

  const likesRef = useRef(null);
  const dislikesRef = useRef(null);
  const popupRef = useRef(null);

  const onMouseEnter = (e) => {
    popupRef.current.style.opacity = '1';
  };

  const onMouseLeave = () => {
    popupRef.current.style.opacity = '0';
  }

  const likesPercentage = Math.floor((likes / (likes + dislikes)) * 100) || 0;
  const dislikesPercentage = Math.ceil((dislikes / (likes + dislikes)) * 100) || 0;

  useEffect(() => {
    likesRef.current.style.width = likesPercentage + '%';
    dislikesRef.current.style.width = dislikesPercentage + '%';
  }, [likesRef, dislikesRef, likesPercentage, dislikesPercentage]);

  return (
    <div className='flex flex-1 flex-col' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} >
      <Popup ref={popupRef} likes={likes} dislikes={dislikes} />
      <div className='flex flex-1 bg-app-third mx-app-s cursor-pointer' >
        <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={likesRef} className={`h-app-base-2 flex items-center bg-app-primary text-black`}></span>
        <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={dislikesRef} className={`h-app-base-2 flex items-center justify-end bg-app-secondary text-black `}></span>
      </div>
    </div>
  );
});
