import React, { useState } from 'react';
import './ShareButton.styles.css';
import { Icons } from '../../UI';

export const ShareButton = ({ questionId }) => {

  const [showCopiedNotification, setShowCopiedNotification] = useState(false);


  const handleCopy = async (event) => {
    event.stopPropagation();

    const hostname = window.location.hostname;
    const port = window.location.port;
    const urlToQuestion = `${hostname}${port ? `:${port}` : ''}/question/${questionId}`;
    const fallbackInput = document.createElement('input');
    fallbackInput.value = urlToQuestion;

    // Copy to clipboard this way, becouse on phone doesn't work wit navigator.clipboard.writetext()
    document.body.appendChild(fallbackInput);
    fallbackInput.select();
    document.execCommand('copy');
    document.body.removeChild(fallbackInput);
    // Show the notification
    setShowCopiedNotification(true);

    // Hide the notification after 2 seconds
    setTimeout(() => {
      setShowCopiedNotification(false);
    }, 2000);
  };

  return (
    <div className='share-button-wrapper'
    >
      <button onClick={(event) => event.stopPropagation()} className="question-card-actions-buttons">
        <Icons variant={Icons.variants.SHARE_ARROW} />
      </button>
      <div className="share-popup">
        <button onClick={handleCopy} className="copy-button">
          Copy
        </button>
      </div>
      {/* Notification at the bottom */}
      {showCopiedNotification && (
        <div className="copied-notification">
          Copied
        </div>
      )}
    </div>
  );
};
