import React from 'react';
import { useGetUserQuestionsQuery } from '../../apis/main';
import { useParams } from 'react-router-dom';
import { QuestionCard } from '../../components/QuestionCard/QuestionCard';
import { Spinner } from '../../UI/Spinner/Spinner';

export const UserQuestions = () => {

  const { userId } = useParams();

  const { data, isLoading } = useGetUserQuestionsQuery(userId);

  if (isLoading) {
    return <div className='flex justify-center'>
      <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
    </div>
  }

  return (
    <>
      {
        data.length === 0
          ? <div className='text-white mt-app-base text-app-lg flex justify-center'>
            <p>You haven't post any pinions yet</p>
          </div>
          : null
      }
      {
        data.length > 0
          ? data.map((question) => {
            return <QuestionCard data={question} key={question.questionId} />
          })
          : null
      }
    </>
  );
};
