import React from 'react';
import { MiddleSide } from '../MiddleSide/MiddleSide';
import { Navigation } from './Navigation';
import { UserName } from '../../UI';
import RightSide from './RightSide';
import { Header } from './Header';

export const Desktop = () => {
  const userId = localStorage.getItem('userId');
  const displayName = localStorage.getItem('displayName');
  const userName = localStorage.getItem('userName');

  let userIsLogged = !!userId;


  return (
    <>
      <Header />

      <div className='hidden laptop:flex flex-1 justify-center overflow-scroll no-scrollbar'>
        <div className='flex flex-1 max-w-app-page-size justify-center'>
          <div className="hidden laptop:flex flex-1 flex-col  max-w-[300px] overflow-auto px-app-2xl py-app-base bg-app-card rounded-app-lg m-app-base">
            <Navigation />
            {
              userIsLogged
                ? <div className='mt-20 flex-col'>
                  <p>{displayName}</p>
                  <UserName userName={userName} />
                </div>
                : null
            }
          </div>
          <MiddleSide />
          <div className="no-scrollbar hidden laptop:flex flex-1 overflow-y-auto p-app-base min-w-[350px] max-w-[450px] bg-app-card rounded-app-lg m-app-base">
            <RightSide />
          </div>
        </div>
      </div>
    </>
  );
};
