import React, { memo } from 'react';
import * as Variants from './IconVariants/index';

export const Icons = memo((props) => {

  const VariantComponent = React.useMemo(() => {
    return Variants[props.variant] || <div>There is missing variant for {props.variant}</div>;
  }, [props.variant]);

  return (
    <VariantComponent {...props} >
      {props.children}
    </VariantComponent>
  );
});

Icons.variants = {
  ARROW_UP: 'ArrowUp',
  BELL_FULL: 'BellFull',
  BOOKMARK: 'BookMark',
  BOOKMARK_FULL: 'BookMarkFull',
  CALENDAR: 'Calendar',
  COMMENT: 'Comment',
  DISLIKE: 'Dislike',
  EDIT: 'Edit',
  DISLIKE_FULL: 'DislikeFull',
  EYE: 'Eye',
  EYE_FULL: 'EyeFull',
  EYE_FULL_SLASH: 'EyeFullSlash',
  HOME_FULL: 'HomeFull',
  ID_CARD: 'IdCard',
  LAYER: 'Layer',
  GLASS: 'Glass',
  HAMBURGER: 'Hamburger',
  HAMBURGER_2: 'Hamburger2',
  LIKE: 'Like',
  LIKE_FULL: 'LikeFull',
  LOCATION: 'Location',
  PLUS_SQUARE: 'PlusSquare',
  POWER_OFF: 'PowerOff',
  PROFILE_FULL: 'ProfileFull',
  SHARE: 'Share',
  SHARE_ARROW: 'ShareArrow',
  SHARE_FULL: 'ShareFull',
  USER_FULL: 'UserFull',
  X: 'X',
};
