import React, { forwardRef } from 'react';

export const Comment = forwardRef(({ classes, style = {}, children, forwardRef = null }, ref) => {


  return (
    <i ref={forwardRef} className={`fa-regular fa-comment-dots ${classes || ''}`} style={style}>
      {children}
    </i>
  );
});
