import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icons } from '../../UI';

export const SearchInput = () => {

  const navigate = useNavigate();
  const [title, setTitle] = useState('');

  useEffect(() => {

    // Don't navigate if title is empty
    if (title.trim() === '') {
      return;
    }

    const handler = setTimeout(() => {
      navigate(`/search-questions/${title}`);
      setTitle('');
    }, 1000);

    return () => {
      clearTimeout(handler);
    };

  }, [title, navigate]);

  const handleSearchInputChange = (event) => {
    setTitle(event.target.value);
  };

  return (
    <div className='flex flex-1 min-w-[350px] max-w-[450px] justify-end items-center relative bg-app-card mx-app-base px-app-base rounded-br-app-lg'>
      <input className='h-[34px] flex-1 rounded-full bg-app-gray pl-[30px] pr-app-sm outline-none'
        onChange={handleSearchInputChange}
        type="text"
        value={title}
      />
      <span className='absolute left-[20px]'>
        <Icons variant={Icons.variants.GLASS} />
      </span>
    </div>
  );
};
