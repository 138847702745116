import React from 'react';
import './Hallow.styles.css';

export const Hallow = ({ color, onClick }) => {
  return (
    <button onClick={onClick} className='voiting-man-icon-hallow '>
      <span className={`voiting-man-circle-hallow voiting-man-hallow-${color}`}></span>
      <span className={`voiting-man-rectangle-hallow voiting-man-hallow-${color}`}></span>
    </button>
  );
};
