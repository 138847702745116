import React from 'react';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

export const Layout = () => {
  return (
    <>
      <Desktop />
      <Mobile />
    </>
  );
};
