import React from "react";
import { HeaderImage } from "./HeaderImage/HeaderImage";
import { ProfileImage } from "./ProfileImage/ProfileImage";
import { ProfileNav } from "./ProfileNav/ProfileNav";
import { UserData } from "./UserData/UserData";
import { useParams } from "react-router-dom";
import { UserQuestions } from "../UserQuestions/UserQuestions";
import { useGetUserQuery } from "../../apis/main";
import {  Spinner } from "../../UI";

export const Profile = () => {
  const { userId } = useParams();
  const currentUserId = localStorage.getItem('userId');
  const { data, isLoading } = useGetUserQuery(userId, { forceRefetch: true });
  
  if (isLoading) {
    return <div className='flex justify-center'>
      <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
    </div>
  }

  return (
    // Here the key={userId} is used to make the router to refetch if we are on this page and only change the userId params, otherwise it doesn't rerender
    <div key={userId} className="flex flex-1 flex-col relative overflow-y-scroll no-scrollbar">
      <ProfileNav displayName={data?.displayName} posts={data?.questions} />
      <div className="flex flex-col mb-app-2xl relative">
        <div className="w-full h-80 relative">
          <HeaderImage imageUrl={data?.bannerImgUrl} userId={userId} currentUserId={currentUserId} />
          <ProfileImage imageUrl={data?.profileImgUrl} userId={userId} currentUserId={currentUserId} />
        </div>
      </div>
      <UserData
        displayName={data?.displayName}
        userName={data?.userName}
        createdAt={data?.createdAt}
        isFollowing={data?.isFollowing}
        followers={data?.followers}
        following={data?.following}
      />
      <div className="mt-app-lg pl-app-sm">
        <h2 className=" text-center text-app-lg2 font-semibold mb-app-sm ">Posts</h2>
      </div>
      <UserQuestions />
    </div>
  );
};
