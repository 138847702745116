import React from 'react';
import { QuestionCard } from '../../components';
import { useGetQuestionsByTitleQuery } from "../../apis/main";
import { useParams } from 'react-router-dom';
import { Spinner } from '../../UI';


export const SearchQuestions = () => {

  const { title } = useParams();
  const { data, isLoading } = useGetQuestionsByTitleQuery({ title });

  if (isLoading) {
    return <div className='flex justify-center'>
      <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
    </div>
  }

  return (
    <>
      {
        data?.length === 0
          ? <div className='text-white mt-app-base text-app-lg flex justify-center'>
            <p>No questions found</p>
          </div>
          : null
      }
      {data.map((question) => {
        return <QuestionCard data={question} key={question.questionId} />
      })}
    </>
  );
};
