import React from 'react';
import './Modal.styles.css';
import { useNavigate } from 'react-router-dom';

export const Modal = ({ children, navigateTo }) => {
  let navigate = useNavigate();

  function onNavigateBack() {
    navigate(navigateTo);
  }

  return (
    <div className="modal" >
      <Backdrop onNavigateBack={onNavigateBack}>
        {children}
      </Backdrop>
    </div>
  );
};


const Backdrop = ({ children, onNavigateBack }) => {

  return (
    <>
      <div onClick={onNavigateBack} className="backdrop"></div>
      {children}
    </>
  );
};
