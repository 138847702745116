import React, { forwardRef } from 'react';
import './Popup.styles.css';
import { PinionManIcon } from '../../UI';

export const Popup = forwardRef((props, ref) => {

  const { likes, dislikes } = props;

  return (
    <div className='voting-bar-popup-container'>
      <div ref={ref} className="voting-bar-popup">
        <div className='flex'>
          <PinionManIcon variant={PinionManIcon.Variants.SMALL_ONE} color={'orange'} />
          <span className='votes-count'>{likes.toString()}</span>
        </div>
        <div className='flex'>
          <PinionManIcon variant={PinionManIcon.Variants.SMALL_ONE} color={'purple'} />
          <span className='votes-count'>{dislikes.toString()}</span>
        </div>
      </div>
    </div>
  );
});
