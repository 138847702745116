import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainService } from "../../services/main";
import { CheckBox, Input } from "../../UI";
import './SignupForm.styles.css';
import { useInput } from "../../hooks/useInput";
import { Spinner } from "../../UI/Spinner/Spinner";
import isEmail from "validator/lib/isEmail";
import { saveUser } from "../../utils/auth";

export const SignUpForm = () => {

  const {
    inputValue: firstNameValue,
    hasError: firstNameError,
    onInputChange: onFirstNameChange,
    onInputBlur: onFirstNameBlur,
  } = useInput((value) => (/^[a-zA-Z]+$/).test(value));
  const {
    inputValue: lastNameValue,
    hasError: lastNameError,
    onInputChange: onLastNameChange,
    onInputBlur: onLastNameBlur,
  } = useInput((value) => (/^[a-zA-Z]+$/).test(value));

  const {
    inputValue: emailValue,
    hasError: emailError,
    onInputChange: onEmailChange,
    onInputBlur: onEmailBlur,
  } = useInput(isEmail);
  const {
    inputValue: passwordValue,
    hasError: passwordError,
    onInputChange: onPasswordChange,
    onInputBlur: onPasswordBlur,
  } = useInput((value) => value.length >= 6);
  const {
    inputValue: confirmPasswordValue,
    hasError: confirmPasswordError,
    onInputChange: onConfirmPasswordChange,
    onInputBlur: onConfirmPasswordBlur,
  } = useInput((value) => value === passwordValue);

  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();


    if (firstNameError || lastNameError || emailError
      || passwordError || confirmPasswordError || !isChecked) {
      return;
    }

    if (passwordValue !== confirmPasswordValue) {
      return;
    }

    setIsLoading(true);
    setServerError(false);

    const submitLogin = async () => {
      const body = {
        firstName: firstNameValue,
        lastName: lastNameValue,
        email: emailValue,
        password: passwordValue,
      }

      try {
        const data = await MainService.submitRegister(body);
        const { token, email, userId, displayName, userName } = data;
        saveUser({ token, email, userId, displayName, userName });
        navigate('/');

      } catch (error) {
        setServerError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    submitLogin();
  }

  const handleCheckBoxChange = (isChecked) => setIsChecked(isChecked);


  return (
    <>
      {isLoading && <Spinner variant={Spinner.variants.CIRCLE} />}
      <div className="signup-form-wrapper">
        <div className="signup-form-container">
          <div className="flex justify-start mb-[50px]">
            <div className="w-[200px]">
              <img src="/logo-new.png" alt="" />
            </div>
          </div>
          {
            serverError
              ? <span className="text-app-base text-red-500 mb-app-xl">{serverError}</span>
              : null
          }
          <form onSubmit={handleSubmit} className="flex flex-1 flex-col justify-center gap-app-xl ">
            <div className="flex flex-1 gap-app-base">
              <Input
                variant={Input.variants.OUTLINE}
                name={'First Name'}
                id={'first-name-id'}
                value={firstNameValue}
                errorMessage={'Must contain only letters'}
                hasError={firstNameError}
                onChange={onFirstNameChange}
                onBlur={onFirstNameBlur}
                required={true}
              />
              <Input
                variant={Input.variants.OUTLINE}
                name={'Last Name'}
                id={'last-name-id'}
                value={lastNameValue}
                errorMessage={'Must contain only letters'}
                hasError={lastNameError}
                onChange={onLastNameChange}
                onBlur={onLastNameBlur}
                required={true}
              />
            </div>
            <Input
              variant={Input.variants.OUTLINE}
              name={'email'}
              id={'emailid'}
              value={emailValue}
              errorMessage={'Invalid email'}
              hasError={emailError}
              onChange={onEmailChange}
              onBlur={onEmailBlur}
              required={true}
            />
            <Input
              variant={Input.variants.OUTLINE_PASSWORD}
              name={'password'}
              id={'passwordid'}
              value={passwordValue}
              errorMessage={'Password must be at least 6 charachters'}
              hasError={passwordError}
              onChange={onPasswordChange}
              onBlur={onPasswordBlur}
              required={true}
            />
            <Input
              variant={Input.variants.OUTLINE_PASSWORD}
              name={'confirm-password'}
              id={'confirm-passwordid'}
              value={confirmPasswordValue}
              errorMessage={'Passwords does not match'}
              hasError={confirmPasswordError}
              onChange={onConfirmPasswordChange}
              onBlur={onConfirmPasswordBlur}
              required={true}
            />

            <CheckBox
              variant={CheckBox.variants.OUTLINE} handleCheckBoxChange={handleCheckBoxChange}
              label={'I accept the Terms of Service and Privacy Policy'}
            />

            <button
              className="flex p-app-base rounded-app-base justify-center bg-[#3742fa] hover:bg-[#2828dc] disabled:opacity-40 disabled:hover:bg-[#3742fa]"
              disabled={!isChecked}
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

