import { ButtonLoader } from '../../../ButtonLoader/ButtonLoader';

export const OutlineOnHoverRoundedDangerLarge = ({ onClick, children, disabled = false, isLoading = false }) => {
  return (
    <button className='disabled:cursor-not-allowed flex bg-app-white disabled:opacity-[0.4] items-center text-app-background-primary font-semibold rounded-app-xl px-app-2xl py-app-base border-app-s hover:bg-red-800 hover:border-red-600 hover:text-red-600 hover:bg-opacity-20 disabled:bg-red-800 disabled:border-red-600 disabled:text-red-600 disabled:bg-opacity-20 transition-all '
      onClick={onClick} disabled={disabled || isLoading}
    >
      {
        isLoading
          ? <ButtonLoader variant={ButtonLoader.variants.LARGE} />
          : null
      }
      {children}
    </button>
  )
}