import React from 'react';
import './VoitingCard.styles.css';
import { VotingBar } from './VotingBar';
import { PinionManIcon } from '../../UI';
import { useAnswer } from '../../hooks/useAnswer';

export const VoitingCard = (props) => {

  const { likes, dislikes, answered, onAnswer } = props;
  
  const {
    feedback,
    handleLike: handleLikeQuestion,
    handleDisike: handleDislikeQuestion,
  } = useAnswer({ answered, likes, dislikes });

  const likeHandler = (event) => {
    event.stopPropagation();
    handleLikeQuestion();
    onAnswer(true);
  };

  const dislikeHandler = (event) => {
    event.stopPropagation();
    handleDislikeQuestion();
    onAnswer(false);
  };

  return (
    <div className='flex mb-app-base items-end'>
      <PinionManIcon
        variant={feedback.answered === true ? PinionManIcon.Variants.PRIMARY : PinionManIcon.Variants.HALLOW}
        color={'orange'}
        onClick={likeHandler}
      />
      <VotingBar
        likes={feedback.likes}
        dislikes={feedback.dislikes}
      />
      <PinionManIcon
        variant={
          (feedback.answered === null || feedback.answered === true)
            ? PinionManIcon.Variants.HALLOW
            : PinionManIcon.Variants.PRIMARY
        }
        color={'purple'}
        onClick={dislikeHandler}
      />
    </div>
  );
};