import React from 'react';
import { ButtonLoader } from '../../../ButtonLoader/ButtonLoader';

export const RoundedBlue = ({ onClick, children, disabled = false, isLoading }) => {
  return (
    <button
      className='disabled:cursor-not-allowed disabled:opacity-[0.4] flex items-center rounded-[25px] bg-[#45A8FC] hover:bg-[#69BAFD] mt-app-sm px-app-base text-black p-app-s disabled:text-black disabled:bg-[#69BAFD]'
      onClick={onClick} disabled={disabled || isLoading}
    >
      {
        isLoading
          ? <ButtonLoader variant={ButtonLoader.variants.SMALL} />
          : null
      }
      {children}
    </button>
  );
};
