import React, { memo } from 'react';
import * as Variants from './Variants/index';

export const PinionManIcon = memo((props) => {

  const VariantComponent = React.useMemo(() => {
    return Variants[props.variant] || <div>There is missing variant for {props.variant}</div>;
  }, [props.variant]);

  return (
    <VariantComponent {...props} />
  );
});

PinionManIcon.Variants = {
  PRIMARY: 'Primary',
  SMALL_ONE: 'SmallOne',
  HALLOW: 'Hallow',
};