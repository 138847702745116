import React from 'react';

export const Dislike = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <path d="M32,48H80a0,0,0,0,1,0,0V152a0,0,0,0,1,0,0H32a8,8,0,0,1-8-8V56A8,8,0,0,1,32,48Z" fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
      <path d="M80,152l40,80a32,32,0,0,0,32-32V176h61.9a15.9,15.9,0,0,0,15.8-18l-12-96a16,16,0,0,0-15.8-14H80" fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
    </svg>
  );
};
