import React from 'react'

export const Hamburger = ({ classes }) => {
  return (

    <svg width="18" height="14" viewBox="0 0 18 14" className={classes || " my-auto"} xmlns="http://www.w3.org/2000/svg">
      <g id="hamburger menu">
        <path id="|" d="M-5.24537e-08 1.80059L0 0.600585L18 0.600586L18 1.80059L-5.24537e-08 1.80059Z" />
        <path id="|_2" d="M-5.24537e-08 7.40019L0 6.20019L18 6.2002L18 7.4002L-5.24537e-08 7.40019Z" />
        <path id="|_3" d="M-5.24537e-08 13.4002L0 12.2002L18 12.2002L18 13.4002L-5.24537e-08 13.4002Z" />
      </g>
    </svg>
  )
}
