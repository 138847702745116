import React from 'react';
import { NavLink } from 'react-router-dom';

export const Primary = (props) => {

  const { children, to } = props;

  return (
    <NavLink to={to} className='border-b-app-s border-b-transparent hover:border-b-app-white'>
      {children}
    </NavLink>
  );
};
