import React from 'react';
import './Header.css';
import { SearchInput } from './SearchInput';
import { NavLink } from 'react-router-dom';

export const Header = () => {
  return (
    <div className='hidden laptop:flex justify-center sticky z-10  top-0 '>
      <div className='header-container'>
        <div className='flex flex-1 max-w-[300px] bg-app-card mx-app-base px-app-2xl py-app-base rounded-bl-app-lg'>
          <NavLink to={'/'} className='w-[200px] ' >
            <img src="/logo-new.png" alt="" />
          </NavLink>
        </div>
        <div className='flex flex-1 min-w-[600px] max-w-app-middle-side justify-around items-center bg-app-card '>
          {/* <Links variant={Links.variants.PRIMARY} >For you</Links>
          <Links variant={Links.variants.PRIMARY} >Folowing</Links> */}
        </div>
        <SearchInput />
      </div>
    </div>
  );
};
