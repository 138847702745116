import React, { useState } from 'react';
import './CreateQuestion.styles.css';
import { Input } from '../../UI';
import { useInput } from '../../hooks/useInput';
import { Spinner } from '../../UI/Spinner/Spinner';
import { getToken, loguotUser } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { useAddQuestionMutation } from '../../apis/main';

export const CreateQuestion = () => {

  const [addQuestion, { isLoading }] = useAddQuestionMutation();

  const navigate = useNavigate();

  const {
    inputValue: newQuestionValue,
    hasError: newQuestionError,
    onInputChange: onNewQuestionChange,
    onInputBlur: onNewQuestionBlur,
  } = useInput((value) => value.length > 0);

  const [serverError, setServerError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newQuestionValue.length <= 0) return;

    const token = getToken();

    const submitAddQuestion = async () => {

      const body = {
        token,
        title: newQuestionValue,
      };

      const result = await addQuestion(body);

      if (result.error) {
        console.log({ result });
        if (result.error.message === 'Not authorized') {
          loguotUser();
          navigate('/login', { state: { message: 'Your session has expired. Please log in again.' } });
        } else {
          setServerError(result.error.message)
        }
      } else {
        const userId = localStorage.getItem('userId');
        navigate(`/user-questions/${userId}`);
      }
    }
    submitAddQuestion();
  };

  return (
    <>
      {isLoading && <Spinner variant={Spinner.variants.CIRCLE} />}
      <div className="signin-form-wrapper">
        <div className="signin-form-container">
          {
            serverError
              ? <span className="text-app-base text-red-500 mb-app-xl">{serverError}</span>
              : null
          }
          <form onSubmit={handleSubmit} className="flex flex-1 flex-col justify-center gap-app-xl ">
            <Input
              variant={Input.variants.OUTLINE}
              name={'add-question'}
              id={'add-question-id'}
              value={newQuestionValue}
              errorMessage={'Must contain only letters'}
              hasError={newQuestionError}
              onChange={onNewQuestionChange}
              onBlur={onNewQuestionBlur}
              required={true}
            />

            <button
              className="flex mt-app-base p-app-base rounded-app-base justify-center bg-[#3742fa] hover:bg-[#2828dc] disabled:opacity-40 disabled:hover:bg-[#3742fa]">
              Add question
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
