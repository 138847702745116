import React from 'react';
import { useGetQuestionQuery, usePostCommentMutation } from '../../apis/main';
import { QuestionCard } from '../../components/QuestionCard/QuestionCard';
import { CommentCard } from '../../components/CommentCard/CommentCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '../../UI/Spinner/Spinner';
import { getToken, loguotUser } from '../../utils/auth';
import { CommentForm } from '../../components';

export const Question = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { questionId } = useParams();
  const token = getToken();

  const [postComment] = usePostCommentMutation();
  const { data, isLoading } = useGetQuestionQuery({ questionId });

  const handleComment = (content) => {
    const data = {
      questionId,
      content,
    };

    (async () => {
      const result = await postComment(data);

      if (result.error) {
        if (result.error.message === 'Not authorized') {
          loguotUser();
          navigate('/login', { state: { backgroundLocation: location, message: 'Your session has expired. Please log in again.' } });
        }
      }

    })();

  };

  if (isLoading) {
    return <div className='flex justify-center'>
      <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
    </div>
  }

  return (
    <div className='no-scrollbar overflow-y-scroll bg-app-card flex flex-1 flex-col rounded-app-base'>
      <QuestionCard data={data} key={data.questionId} />
      <div className='px-app-lg pb-app-lg'>
        {
          token
            ? <CommentForm onSubmit={handleComment} />
            : null
        }
        {
          data.comments?.map(comment => {
            return <CommentCard data={comment} key={comment._id} />
          })
        }
      </div>
    </div>
  );
};
