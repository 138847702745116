import React, { useEffect, useState } from 'react';
import './AvatarPopUp.styles.css';
import { NavLink } from 'react-router-dom';
import { Links } from '../../UI/Links/Links';
import { Spinner, UserName } from '../../UI';
import { MainService } from '../../services/main';

export const AvatarPopUp = (props) => {

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const data = await MainService.getUser(props.userId);
        setUserData(data);
        console.log({ data });
      } catch (error) {
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    })();

  }, [props.userId])

  const displayName = userData?.displayName;
  const imageUrl = userData?.profileImgUrl;
  const userId = userData?.userId;
  const userName = userData?.userName;
  const followersCount = userData.followers;
  const followingCount = userData.following;
  const pinions = userData.questions;
  // const isLoading = props.isLoading;

  // TO DO 
  // Text of the Moto div 
  // overflows when text is to long

  // <div className='avatar-moto'>
  //   <p >I love coding and digital Marketing...awlkdnakdnadnandandadandadwnawniandapdnapdnapdnaipdnapdnpandpandpandpa</p>
  // </div>

  const onClick = (e) => e.stopPropagation();

  if (isLoading) {
    return <div className='avatar-card'>
      <div className='flex justify-center'>
        <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
      </div>
    </div>
  }

  return (
    <div onClick={onClick} className='avatar-card'>
      <div className='flex pb-app-base'>
        <NavLink className='w-app-3xl' to={`/profile/${userId}`}>
          <img
            src={!imageUrl ? '/profile-man.png' : `${BASE_URL}/${imageUrl}`} alt=""
            className='bg-gray-200 rounded-full'
          />
        </NavLink>
        <div className='flex flex-col'>
          <div className='px-app-base'>
            <Links variant={Links.variants.PRIMARY} to={`/profile/${userId}`}>
              {displayName}
            </Links>
          </div>
          <div className='text-app-gray'>
            <UserName userName={userName} />
          </div>
        </div>
      </div>
      <div className='avatar-moto'>
        <p >I love coding and digital Marketing...awlkdnakdnadnand andadandadwnawniandapdnapdnapdna ipdnapdnpandpandpandpa</p>
      </div>

      <div className='flex gap-app-base'>
        <Links variant={Links.variants.PRIMARY} to={`/user-questions/${userId}`} className='border-b-app-s border-b-transparent hover:border-b-app-white'>
          <span className='text-app-lg2-avatar-card mr-app-xs align-text-bottom'>
            {pinions}
          </span>
          <span className='text-app-base-avatar-card text-app-gray align-text-bottom'>Pinions</span>
        </Links>
        <Links variant={Links.variants.PRIMARY} to='#' className='border-b-app-s border-b-transparent hover:border-b-app-white'>
          <span className='text-app-lg2-avatar-card mr-app-xs align-text-bottom'>
            {followingCount}
          </span>
          <span className='text-app-base-avatar-card text-app-gray align-text-bottom'>Following</span>
        </Links>
        <Links variant={Links.variants.PRIMARY} to='#' className='border-b-app-s border-b-transparent hover:border-b-app-white'>
          <span className='text-app-lg2-avatar-card mr-app-xs align-text-bottom'>
            {followersCount}
          </span>
          <span className='text-app-base-avatar-card text-app-gray align-text-bottom'>Followers</span>
        </Links>
      </div>
    </div>
  );
};
