import React from 'react';
import * as Variants from './Variants/index';

export const ButtonLoader = React.memo((props) => {

  const VariantComponent = React.useMemo(() => {
    return Variants[props.variant] || <div>There is missing variant for {props.variant}</div>;
  }, [props.variant]);

  return (
    <VariantComponent {...props} />
  );
});

ButtonLoader.variants = {
  LARGE: 'Large',
  SMALL: 'Small',
}