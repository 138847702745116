import React from 'react'
import { useGetQuestionsQuery } from '../../apis/main';
import { NavLink } from 'react-router-dom';

const RightSide = () => {
  const { data, isLoading, error } = useGetQuestionsQuery();
  
  if (isLoading) {
    return <div>Loading....</div>
  }

  if (error) {
    return null;
  }

  return (
    <div className="flex-1 max-w-[450px] pb-app-base">

      <div >
        {
          data.length === 0
            ? <p>No questions found</p>
            : null
        }
        {
          data && data?.map((question) => {
            return <NavLink to={`/question/${question.questionId}`}
              key={`RightSideNavlinkTo${question.questionId}`}
            >
              <p>{question.title}</p>
            </NavLink>
          })
        }
      </div>
    </div>
  )
}

export default RightSide
