import React from 'react'
import { NavLink } from 'react-router-dom'

export const ProfileNav = ({ displayName, posts }) => {
  return (
    <div className="hidden laptop:flex flex-row w-full gap-2 justify-start sticky top-0 z-10 bg-app-background-primary opacity-90">
      <NavLink to={-1} className='mt-app-sm ml-app-sm'>
        <svg viewBox="0 0 24 24" aria-hidden="true" className="h-app-2xl fill-app-gray" > <g>
          <path d="M7.414 13l5.043 5.04-1.414 1.42L3.586 12l7.457-7.46 1.414 1.42L7.414 11H21v2H7.414z"></path></g>
        </svg>
      </NavLink>
      <div className="flex flex-col m-app-sm">
        <h2 className="text-app-lg font-semibold font-app-font-family-primary">{displayName ?? ''}</h2>
        <span className="text-app-gray">{posts ?? 0} posts</span>
      </div>

    </div>
  )
}