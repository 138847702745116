import React, { useState } from 'react';
import { Input } from '../../UI';
import { Button } from '../../UI';
import { useInput } from '../../hooks/useInput';

export const CommentForm = (props) => {

  const { onSubmit } = props;
  const [showButtons, setShowButtons] = useState(false);

  const {
    inputValue: commentValue,
    hasError: commentHasError,
    onInputChange: onCommentChange,
    onInputBlur: onCommentBlur,
    resetInput: resetCommentValue,
  } = useInput((value) => value.length > 0);

  const handleSubmit = (event) => {
    event.preventDefault();

    onSubmit(commentValue);
    resetCommentValue();
    setShowButtons(false);
  };

  const handleCancle = (event) => {
    event.preventDefault();
    setShowButtons(false);
  };

  return (
    <div>
      <div action="" >
        <Input
          variant={Input.variants.BORDER_BOTTOM}
          name={'add-comment'}
          // id={'comment-input'}
          value={commentValue}
          hasError={commentHasError}
          errorMessage={'Field can not be empty'}
          onChange={onCommentChange}
          onBlur={onCommentBlur}
          onFocus={(e) => setShowButtons(true)}
          required={true}
        />
        {
          showButtons
            ? <div className='flex justify-end'>

              <Button onClick={handleCancle} variant={Button.variants.ROUNDED_DARK}>
                Cancle
              </Button>
              <Button disabled={commentValue.length <= 0} onClick={handleSubmit} variant={Button.variants.ROUNDED_BLUE}>
                Comment
              </Button>
            </div>
            : null
        }
      </div>
    </div>
  );
};
