import React, { useRef } from 'react';
import { Icons } from '../../../Icons/Icons';
import { NavLink } from 'react-router-dom';

export const BouncingIconWithText = (props) => {
  const { iconVariant, to, state = {}, text = '', onClick, preventNavigation = false } = props;
  const iconRef = useRef(null);

  const handleMouseEnter = (event) => {
    if (iconRef) {
      iconRef.current.classList.add('fa-bounce');
    }
  };

  const handleMouseLeave = (event) => {
    if (iconRef) {
      iconRef.current.classList.remove('fa-bounce');
    }
  };

  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }

    if (preventNavigation) {
      event.preventDefault(); // Prevent navigation if the condition is met
    }
  };

  return (
    <NavLink className={({ isActive }) =>
      `flex flex-row text-app-lg2 mb-app-sm hover:text-app-secondary ${isActive ? 'text-app-secondary' : 'text-app-gray-light'
      }`
    }
      to={to} state={state}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className='w-[20px] text-center mr-app-sm'>
        <Icons forwardRef={iconRef} variant={iconVariant} />
      </div>
      <p className='flex-1 font-thin !important'>{text}</p>
    </NavLink>
  );
};
