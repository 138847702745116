import React from 'react';

export const UserName = ({ userName }) => {

  let prefixedName = userName ? `@${userName}` : '';

  return (
    <span className='text-app-gray'>{prefixedName}</span>
  );
};
