import React from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';

export const HeaderMobile = () => {

  return (
    <div className='laptop:hidden flex flex-col px-app-base justify-center sticky z-10  top-0 '>
      <div className='flex flex-1 bg-app-card px-app-lg py-app-base rounded-b-app-base'>
        <NavLink to={'/'} className='w-[200px] ' >
          <img src="/logo-new.png" alt="" />
        </NavLink>
      </div>
    </div>
  );
};