import { useState } from "react";



export const useInput = (validationFn) => {

  const [inputValue, setInputValue] = useState('');
  const [inputIsTouched, setInputIsTouched] = useState(false);

  const valueIsValid = validationFn(inputValue);

  const onInputChange = (value) => {
    setInputValue(value);
  };

  const onInputBlur = () => {
    setInputIsTouched(true);
  }

  const resetInput = () => {
    setInputValue('');
  };

  return {
    inputValue,
    hasError: inputIsTouched && !valueIsValid,
    onInputChange,
    onInputBlur,
    resetInput,
  };
};

