import React, { useState } from "react";
import { Button, Icons, UserName } from "../../../UI";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { Links } from "../../../UI/Links/Links";
import { useFollowUserMutation, useUnfollowUserMutation } from "../../../apis/main";
import { DialogComponent } from "../../../components/DialogComponent/DialogComponent";

export const UserData = ({
  displayName, userName, createdAt, isFollowing, followers, following }) => {
  // TODO add user createdAt, folowers in localStorage

  const [followUser, { isLoading: isLoadingAfterFollow }] = useFollowUserMutation();
  const [unfollowUser, { isLoading: isLoadingAfterUnollow }] = useUnfollowUserMutation();

  const { userId } = useParams();
  const currentUserId = localStorage.getItem("userId");
  const isCurrentUser = userId === currentUserId;
  const [userIsFollowed, setUserIsFollowed] = useState(isFollowing);
  const [errorDialog, setErrorDialog] = useState({
    isOpen: false,
    message: null,
  });

  const handleFollowUser = async (event) => {
    const result = await followUser({ userId });
    
    if (result.error) {
      setErrorDialog(curr => { return { isOpen: true, message: result.error.message } })
    } else {
      setUserIsFollowed(curr => !curr);
    }
  };

  const handleUnfollowUser = async (event) => {
    const result = await unfollowUser({ userId });

    if (result.error) {
      setErrorDialog(curr => { return { isOpen: true, message: result.error.message } })
    } else {
      setUserIsFollowed(curr => !curr);
    }
  };

  return (
    <>
      <DialogComponent
        isOpen={errorDialog.isOpen}
        closeDialog={() => setErrorDialog(curr => { return { isOpen: false, message: null } })}
        content={errorDialog.message}
      />
      <div className="flex flex-col mt-app-2xl pl-app-sm relative">
        <div className="flex justify-end absolute right-0 top-[-15px]">
          {/* Checking if there is logged in user, and that this is not his profile page */}
          {currentUserId && !isCurrentUser
            ? (
              <>
                {userIsFollowed
                  ? (
                    <Button
                      variant={Button.variants.OTLINE_ON_HOVER_ROUNDED_DANGER_LARGE}
                      isLoading={isLoadingAfterUnollow}
                      onClick={handleUnfollowUser}
                    >
                      Unfollow
                    </Button>
                  )
                  : (
                    <Button
                      variant={Button.variants.ROUNDED_GRAY_LARGE}
                      isLoading={isLoadingAfterFollow}
                      onClick={handleFollowUser}
                    >
                      Follow
                    </Button>
                  )}
              </>
            )
            : null}
        </div>
        <h2 className="text-app-lg font-semibold">{displayName || "User"}</h2>
        <span className="text-app-gray text-app-mid mt-1 ">
          {userName ? <UserName userName={userName} /> : "User"}
        </span>
        <div className="flex gap-2 mt-app-sm">
          <Icons
            variant={Icons.variants.CALENDAR}
            classes="w-app-xl fill-app-gray"
          />
          <span className=" text-app-mid mt-0.5 text-app-gray">
            Joined at: <Moment format="MMMM YYYY">{new Date(createdAt)}</Moment>
          </span>
        </div>
        <div className="flex mt-app-sm">
          <div className="mr-app-s">
            <Links to={"#"} variant={Links.variants.PRIMARY} >
              <span className="text-app-mid font-bold mr-[2px]">{followers}</span>
              <span className="text-app-gray">Followers</span>
            </Links>
          </div>
          <div >
            <Links to={"#"} variant={Links.variants.PRIMARY}>
              <span className="text-app-mid font-bold mr-[2px]">{following}</span>
              <span className="text-app-gray">Following</span>
            </Links>
          </div>
        </div>
      </div>
    </>
  );
};
