import { ButtonLoader } from '../../../ButtonLoader/ButtonLoader';


export const RoundedGrayLarge = ({ onClick, children, disabled = false, isLoading = false }) => {
  return (
    <button className='disabled:cursor-not-allowed disabled:opacity-[0.4] bg-app-gray-light flex items-center rounded-app-xl px-app-2xl py-app-base font-semibold hover:bg-app-white hover:text-app-background-primary disabled:bg-app-white disabled:text-app-background-primary  transition-all'
      onClick={onClick} disabled={disabled || isLoading}
    >
      {
        isLoading
          ? <ButtonLoader variant={ButtonLoader.variants.LARGE} />
          : null
      }
      {children}
    </button>
  )
}