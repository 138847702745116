import React from 'react';
import './Circle.styles.css';

export const Circle = () => {
  return (
    <div className='flex fixed justify-center items-center top-0 left-0 w-full h-full z-[1000000]'>
      <div className='absolute  w-full h-full bg-black opacity-60 '></div>
      <div className='felx'>
        <div className="loader"></div>
      </div>
    </div>
  );
};
