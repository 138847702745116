import React from 'react';
import { useGetUserBookmarksQuery } from '../../apis/main';
import { Spinner } from '../../UI/Spinner/Spinner';
import { QuestionCard } from '../../components/QuestionCard/QuestionCard';

export const UserBookmarks = () => {

  const userId = localStorage.getItem('userId');
  const { data, isLoading } = useGetUserBookmarksQuery(userId);

  if (isLoading) {
    return <div className='flex justify-center'>
      <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
    </div>
  }

  return (
    <>
      {
        data?.length === 0
          ? <div className='text-white mt-app-base text-app-lg flex justify-center'>
            <p>You don't have any bookmarks</p>
          </div>
          : null
      }
      {
        data.map((question) => {
          return <QuestionCard data={question} key={question.questionId} />
        })
      }
    </>
  );
};
