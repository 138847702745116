import React, { useCallback, useState } from 'react';
import './CommentCard.styles.css';
import { Comment } from './Comment';
import { CommentForm } from '../CommentForm/CommentForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getToken, loguotUser } from '../../utils/auth';
import { Replies } from '../Replies/Replies';
import { Icons } from '../../UI';
import { useAnswerCommentMutation, useReplyCommentMutation } from '../../apis/main';

export const CommentCard = ({ data }) => {
  const replies = data.replies ?? [];
  const token = getToken();

  const [replyComment] = useReplyCommentMutation();
  const [answerComment] = useAnswerCommentMutation();

  const location = useLocation();
  const navigate = useNavigate();
  const [showReplies, setShowReplies] = useState(false);

  const [expandedItem, setExpandedItem] = useState(null);

  const toggleItem = (index) => {
    setExpandedItem((curr) => curr === index ? null : index);
    setShowReplies(curr => !curr);
  };

  const { questionId } = useParams();
  const commentId = data._id;

  const handleReply = useCallback((content) => {

    const data = {
      questionId,
      content,
      commentId,
    };

    (async () => {
      const result = await replyComment(data);

      if (result.error) {
        if (result.error.message === 'Not authorized') {
          loguotUser();
          navigate('/login', { state: { backgroundLocation: location, message: 'Your session has expired. Please log in again.' } });
        }
      }

    })();
  }, [questionId, commentId, navigate, location, replyComment]);

  const handleCommentAnswer = useCallback(async (commentId, answer) => {
    const result = await answerComment({ commentId, answer });

    if (result.error) {
      if (result.error.message === 'Not authorized') {
        loguotUser();
        navigate('/login', { state: { backgroundLocation: location, message: 'Your session has expired. Please log in again.' } });
      }
    }

  }, [navigate, location, answerComment]);


  return (
    <div >
      <Comment data={data} onAnswer={handleCommentAnswer} />
      <div className='ml-[52px]'>
        {
          token
            ? <CommentForm onSubmit={handleReply} />
            : null
        }
      </div>
      <div className='ml-[52px]'>
        {
          replies > 0
            ? <div className="divide-y divide-slate-200 ">

              <div className="">
                <div className='ml-[10px]'>
                  <button
                    type="button"
                    className="flex items-center w-full text-left font-semibold py-2 text-blue-600"
                    aria-expanded={expandedItem === data._id}
                    onClick={() => toggleItem(data._id)}
                  >
                    <Icons variant={Icons.variants.ARROW_UP}
                      classes={`transition-transform duration-500 ${showReplies ? 'rotate-0' : 'rotate-180'}`}
                    />
                    <span className='ml-app-base '>{replies} Replies</span>
                  </button>
                </div>
                <div
                  role="region"
                  className={`grid text-sm  overflow-hidden transition-all duration-300 ease-in-out ${expandedItem === data._id
                    ? "grid-rows-[1fr] opacity-100"
                    : "grid-rows-[0fr] opacity-0"
                    }`}
                >
                  <div className="overflow-hidden">
                    {
                      showReplies
                        ? <Replies handleAnswer={handleCommentAnswer} commentId={commentId} />
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>

            : null
        }
      </div>
    </div>
  );
};
