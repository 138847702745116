import React from 'react';
import '../Styles/Outline.styles.css';

export const Outline = (props) => {

  const { type, id, name, value, onChange, onBlur, required, hasError, errorMessage } = props;

  const capitalizedFirstLetter = React.useCallback((str) => {
    str = str.replace('-', ' ');
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }, []);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleBlur = () => onBlur();

  return (
    <div className='flex flex-1 flex-col'>
      <div className="input-container">
        <input
          type={type || 'text'}
          id={id}
          placeholder={name}
          required={required || false}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label htmlFor={id}>{capitalizedFirstLetter(name)}</label>
      </div>
      {
        hasError
          ? <span className='text-red-500 pl-app-base pt-app-s text-app-base'>
            {errorMessage}
          </span>
          : null
      }
    </div>
  );
};
