import React, { useEffect, useRef, useState } from 'react'
import { Icons, Spinner } from '../../../UI'
import { DialogComponent } from '../../../components/DialogComponent/DialogComponent';
import { useUploadUserImageMutation } from '../../../apis/main';

export const HeaderImage = ({ imageUrl, userId, currentUserId }) => {
  const [uploadUserImage, { isLoading }] = useUploadUserImageMutation();

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imgSrc, setImgSrc] = useState(imageUrl ? `${BASE_URL}/${imageUrl}` : `/incest-cover.jpg`);

  const changeImgHandler = () => {
    inputRef.current.click();
  }
  useEffect(() => {
    if (!file) return;

    (async () => {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('imageUrlFor', 'banner');

      const result = await uploadUserImage(formData);

      if (result.error) {
        setErrorMessage(result.error.message);
        setIsOpen(true);
      } else {
        setImgSrc(`${BASE_URL}/${result.data.imgUrl}`);
      }

    })();

  }, [file, BASE_URL, uploadUserImage]);

  if (userId !== currentUserId) {
    return (
      <div className='h-full'>
        <img
          className={`object-cover w-full h-full  max-h-80 rounded-app-base`}
          src={imgSrc}
          alt=''
        />
      </div>
    );
  }

  return (
    <>
      <DialogComponent
        isOpen={isOpen}
        closeDialog={() => setIsOpen(false)}
        content={errorMessage}
      />
      <div className='group h-full' onClick={changeImgHandler}>
        {
          isLoading
            ? <div className='flex h-full justify-center items-center'>
              <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
            </div>
            : <>
              <img
                className={`group-hover:opacity-40 object-cover w-full h-full  max-h-80 rounded-app-base cursor-pointer `}
                src={imgSrc}
                alt=''
              />
              <input type='file' ref={inputRef} onChange={ev => setFile(ev.target.files[0])} className=' invisible'></input>
              <div className={`group-hover:visible absolute bottom-2 right-2 h-7 w-7 invisible cursor-pointer`}>
                <Icons variant={Icons.variants.EDIT} classes={`fill-white`} />
              </div>
            </>
        }

      </div>
    </>
  )
}