import React from 'react';
import { Comment } from '../CommentCard/Comment';
import { useGetRepliesQuery } from '../../apis/main';
import { getToken } from '../../utils/auth';
import { Spinner } from '../../UI';

export const Replies = (props) => {

  const token = getToken();
  const commentId = props.commentId;
  const handleAnswer = props.handleAnswer;


  const { data, isLoading } = useGetRepliesQuery({ token, commentId });

  if (isLoading) {
    return <div className='flex justify-center'>
      <Spinner variant={Spinner.variants.SMALL_CIRCLE} />
    </div>
  }


  return (
    <div>
      {
        data.map(comment => {
          return <Comment data={comment} onAnswer={handleAnswer} key={comment._id} />
        })
      }
    </div>
  );
};
