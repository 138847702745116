import { useState } from "react";

export const useAnswer = ({ answered, likes, dislikes }) => {

  const token = localStorage.getItem('token');

  const [feedback, setFeedback] = useState({ answered, likes, dislikes });

  const handleLike = () => {
    if (!token) return;
    setFeedback(curr => {
      return {
        answered: (curr.answered === null || curr.answered === false) ? true : null,
        likes: curr.answered === true ? curr.likes - 1 : curr.likes + 1,
        dislikes: curr.answered === false ? curr.dislikes - 1 : curr.dislikes,
      }
    });
  };

  const handleDisike = () => {
    if (!token) return;
    setFeedback(curr => {
      return {
        answered: (curr.answered === null || curr.answered === true) ? false : null,
        likes: curr.answered === true ? curr.likes - 1 : curr.likes,
        dislikes: curr.answered === false ? curr.dislikes - 1 : curr.dislikes + 1,
      }
    });
  };

  return {
    feedback,
    handleLike,
    handleDisike,
  };
};